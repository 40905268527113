import React from 'react'

import * as postStyles from './post.module.css'

const Post = (props) => (
  <section className={postStyles.container}>
    <a className={postStyles.headline} href={props.link}>
      <h4 className={postStyles.heading}>{props.title}</h4>
      <div className={postStyles.headlineImage}>
        {props.image ? (
          <img src={props.image} title="Medium post" alt="Medium post" />
        ) : (
          ''
        )}
      </div>
    </a>
    <div className={postStyles.details}>
      <p className={postStyles.subtitle}>{props.subtitle}</p>
    </div>
    <hr />
  </section>
)

export default Post
