import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Footer from '../components/footer'
import Post from '../components/post'

const WritingPage = (props) => (
  <Layout>
    <article>
      <h3 className="centered narrowed-heading">
        I write about improving digital products and organizations on{' '}
        <a href="https://laitine.medium.com/">Medium</a>
      </h3>
      <div className="centered-container">
        {props.data.allMediumPost.edges.map((post, i) => (
          <Post
            key={i}
            title={post.node.title}
            subtitle={post.node.subtitle}
            link={post.node.link}
            image={post.node.image}
          />
        ))}
      </div>
    </article>
    <Footer />
  </Layout>
)

export default WritingPage

export const query = graphql`
  query PostsQuery {
    allMediumPost {
      edges {
        node {
          id
          title
          subtitle
          link
          image
        }
      }
    }
  }
`
